import React, { useState } from "react";
import { ArrowRight } from 'react-bootstrap-icons'

function ElyssianReadMore() {
    const [isShowMore, setIsShowMore] = useState(false);

    const toggleReadMoreLess = () => {
        setIsShowMore(!isShowMore);
    };

    return (
        <div className="ReadMoreSection">
            <h4 className='m-0'>Elyssian Developers</h4>
            
            <p className="mb-0">
                <p className="AboutExpoPara">Throughout our journey, our commitment to our Core Values has made Sanjeevini Group the ideal building partner for Bangalore’s most forward-thinking populous. Started in 2010, Sanjeevini Group continues to be a trusted partner in the commercial, residential, and hospitality sectors, with an extensive portfolio of projects throughout Bangalore, with our core cluster in and around Whitefield.</p>
                <p className="AboutExpoPara">We undertake a variety of projects for a wide range of clientele and our unique and flexible project management systems ensure that a positive outcome is achieved regardless of the size or nature of the project. Our success comes from a steadfast commitment to the client’s vision, a passion for collaborative building practices, and a culture of balance, integrity, leadership, and loyalty.</p>
                <p className="AboutExpoPara">At Sanjeevini, we transform the construction experience through the creation of an environment that fosters collaboration and project team engagement.
</p>

            </p>

            {isShowMore && (
                 <p className="mb-0">
                <h6>Our Vision</h6>
                <p className="AboutExpoPara">To be the most trusted developer in Bangalore</p>

                <h6>Our Purpose</h6>
                <p className="AboutExpoPara">With every brick we lay, we are raising our promise of giving you the very best. We not only committed to building homes but rather engineering lifestyles that are modeled on luxury and charm, at the same time having an aspect of practicality and ease.
</p>

<h6>Our Mission</h6>
                <p className="AboutExpoPara">Our modus operandi lies in our ability to innovate, based on a customer-devoted approach and feedback. We ensure that our clients get truly international quality and amenities in a natural eco-friendly environment. Beyond the accolades, we believe your satisfaction and approval to be our unprecedented treasures.
</p>
            </p>
            )}

            <p className="m-0 AboutExpoButton" onClick={toggleReadMoreLess}>{isShowMore ? "Read Less" : "Read More"} <ArrowRight /></p>
        </div>
    );
}

export default ElyssianReadMore;